import { graphql, PageProps } from 'gatsby'
import * as React from 'react'
import MainLayout from '../components/main-layout'
import '../styles/main.scss'
import PageWrapper from '../components/page/page-wrapper'
import Footer from '../components/footer'
import { Helmet } from 'react-helmet'
import { Page } from '../../../../shared/types/cms'
import PageBlockConsumer from '../components/page/page-blocks-consumer'
import PageContextProvider from '../contexts/pageContext'
import Header from '../components/header/header'

interface ApiPageProps extends PageProps {
  data: {
    site: {
      siteMetadata: {
        titleTemplate: string
      }
    }
  }
  pageContext: {
    apiPage: Page
    localizedPaths: Record<string, string>
  }
}

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        titleTemplate
      }
    }
  }
`

export const ApiPage: React.FC<ApiPageProps> = (props) => {
  return (
    <PageContextProvider pageContext={props.pageContext}>
      <ApiPageInner {...props} />
    </PageContextProvider>
  )
}

export const ApiPageInner: React.FC<ApiPageProps> = ({ pageContext, data }) => {
  const page = pageContext.apiPage
  const pageBlocks = pageContext.apiPage.blocks

  const renderMeta = () => {
    const metaTags: JSX.Element[] = []
    if (pageContext.apiPage.seo) {
      metaTags.push(<meta key="meta_description" name="description" content={page.seo.metaDescription} />)
      metaTags.push(<meta key="meta_title" name="title" content={page.seo.metaTitle} />)

      if (page.seo.preventIndexing) {
        metaTags.push(<meta key="meta_noindex" name="robots" content="noindex" />)
      }

      if (page.seo.structuredData !== null) {
        metaTags.push(
          <script key="meta_structureddata" type="application/ld+json">
            {page.seo.structuredData}
          </script>,
        )
      }

      if (page.seo.meta) {
        page.seo.meta.forEach((metaEntry) => {
          metaTags.push(<meta key={`meta_${metaEntry.id}`} name={metaEntry.name} content={metaEntry.content} />)
        })
      }
    }
    return metaTags
  }

  return (
    <>
      <PageWrapper>
        <MainLayout>
          <Helmet title={page.title} titleTemplate={data.site.siteMetadata.titleTemplate}>
            {renderMeta()}
          </Helmet>
          <Header />

          <div className="api-page">
            <PageBlockConsumer blocks={pageBlocks} />
          </div>
          <Footer />
        </MainLayout>
      </PageWrapper>
    </>
  )
}
export default ApiPage
